.tag {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}
.tag .dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
}
